export const FollowedProjectsIcon = () => {
  return (
    <svg width="224" height="126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="var(--vp-semantic-color-background-main)" d="M0 0h224v125.1H0z" />
      <path fill="var(--vp-semantic-color-background-alternative)" d="M14.6 19.4h113.7v90.4H14.6z" />
      <path stroke="var(--vp-semantic-color-border-quiet)" d="M137 31.6h63.2M137 42.3h37.9" />
      <path d="M113 78l1.2 4.4-21 11.5-1-7.4 20.7-8.6z" fill="#ABB2EB" />
      <path d="M113.7 75.4l2 4.7-22.6 12-1.5-7.7 22.1-9z" fill="#24286B" />
      <path d="M73.6 37.1s19.2-2.5 35.7 26.7l-20.8 7.7L73.6 37z" fill="#F1F0EE" />
      <path d="M73.6 37.1V37h-.3l15 34.9 21.3-7.9-.1-.3a53.6 53.6 0 00-24.1-24.3 28.7 28.7 0 00-11.8-2.4v.2zm.9.2H74l14.6 33.9 20.3-7.6a53 53 0 00-23.7-23.9 28.2 28.2 0 00-10.7-2.4z" fill="#24286B" />
      <path d="M39.5 61.6S30.4 77 30 78.5c-.4 1.3 9.5 5.1 9.5 5.1l52.6 1.7-.5-8.3-4.4-9.3-25-26.8s-22 15.3-22.7 20.7z" fill="#fff" />
      <path d="M62.3 40.6l25 27 4.6 9.3.5 8.7-52.9-1.7v-.3.3h-.1a62.5 62.5 0 01-9.3-4.6l-.3-.4v-.5a20.7 20.7 0 01.5-1.2 90.3 90.3 0 014.4-7.8A1038.8 1038.8 0 0139 62l.2-.5.1-.1c.1-.8.6-1.6 1.2-2.5A94.8 94.8 0 0151 49a214.6 214.6 0 0111.1-8.2l.2.1-.1-.2h.1zm0 .7h-.1a144.8 144.8 0 00-10.9 8.1A94.3 94.3 0 0041 59.3c-.7.9-1 1.7-1.1 2.3h-.3.2v.3l-.4.5a1120.6 1120.6 0 00-9 16.2l.1.3 1 .7a85.7 85.7 0 008 3.7h.2l52.3 1.8-.5-8-4.4-9.3-24.7-26.5z" fill="#24286B" />
      <path d="M30.3 80.4l62.1 6.9 1 6.7s-24.4-1.8-33-3c-8.8-1-32.8-4.8-32.8-4.8l2.7-5.8z" fill="#ABB2EB" />
      <path d="M30 78.4L92 85.3l1 6.8s-24.4-1.9-33-3c-8.8-1.2-32.8-4.9-32.8-4.9l2.7-5.8z" fill="#3643BA" />
      <path d="M29.8 78.2L92.3 85l1 7.2h-.2v-.2.2h-.3a1022.3 1022.3 0 01-32.8-3 1342.7 1342.7 0 01-31.7-4.7l-1-.1v-.3.3H27l2.8-6.3zM27.6 84l.8.2A4463.5 4463.5 0 0060 88.9a527.5 527.5 0 0032.7 2.9l-.9-6.3-61.8-6.8-2.5 5.3z" fill="#24286B" />
      <path d="M88.8 71s2.7 9.5 3.3 14.3l21.6-10s-5.3-9.8-6.5-11.1L88.8 71z" fill="#3643BA" />
      <path d="M107.3 64l.7.9a42 42 0 013 5.2 368.8 368.8 0 012.7 4.7l.1.4-.1.2.2-.1.1.2-22 10.2v-.3A81.9 81.9 0 0088.5 71l.2-.1h-.2l-.1-.2 18.8-6.9zM89 71a75 75 0 011.4 5.2c.7 2.8 1.5 6.2 1.8 8.7l21-9.7V75a211 211 0 00-6.2-10.6l-18 6.6z" fill="#24286B" />
      <path d="M60.5 44L34.8 65.5S49.5 36 73.6 37l-13 7z" fill="#ABB2EB" />
      <path d="M35.6 64.5l24.8-20.7 12.3-6.4C61 37.2 51.8 44 45.3 51a71.6 71.6 0 00-9.7 13.4zm-.8 1l-.2-.2-.5 1 26.5-22 14-7.4h-1c-12.1-.5-21.9 6.6-28.6 13.8a72.1 72.1 0 00-10.3 14.6l.1.2z" fill="#24286B" />
      <path d="M60.5 44l28 27.5S85 45.9 73.6 37c0 0-5.5-.7-13 7z" fill="#ABB2EB" />
      <path d="M73.7 37a2.3 2.3 0 00-.3-.1h-.8c-.6 0-1.6.2-2.8.6-2.3.8-5.6 2.5-9.4 6.3l-.2.2 28.6 28.1v-.6h-.3.2v-.1a33.7 33.7 0 00-.7-4.1c-.5-2.5-1.3-6-2.4-9.8-2.3-7.5-6-16.1-11.9-20.5zm14.5 33.8a62 62 0 00-.6-3.4 99 99 0 00-2.4-9.7 41.3 41.3 0 00-11.8-20.3h-.8c-.6 0-1.5.2-2.6.6-2.3.7-5.5 2.4-9.2 6l27.4 26.8zM73.6 37.4z" fill="#24286B" />
      <path d="M61 56.9s-4-1.7-7.5 2.6c-3.6 4.2-5.5 13.4-4.7 21.1l1.6.3 10.7-24z" fill="#ABB2EB" />
      <path d="M61.2 56.6l-.1.3v-.3l.3.1-10.8 24.5-2-.4v-.2c-.4-3.9-.1-8.1.7-12 .8-3.7 2.2-7.1 4-9.3a7.7 7.7 0 015.3-3 6.7 6.7 0 012.5.3zm-.4.4h-.5c-.4-.2-1-.2-1.7-.2-1.4.1-3.2.8-5 2.8-1.7 2.1-3 5.4-3.9 9.2-.8 3.7-1 7.8-.7 11.6l1.3.2L60.8 57z" fill="#24286B" />
      <path d="M50.4 80.9s.2-10.7 1.5-15c1.4-4.5 4-9.3 8.1-9.2 4.2.2 7.6 3 9 9.2 1.3 6.2.9 17.6.9 17.6l-19.5-2.6z" fill="#24286B" />
      <path d="M55.1 59.7c-1.3 1.7-2.3 4-3 6.2-.6 2.1-1 5.9-1.2 9.1a131.9 131.9 0 00-.2 5.7l19 2.5a115.5 115.5 0 00-1-17.3c-.6-3-1.8-5.2-3.3-6.7A7.9 7.9 0 0060 57c-2-.1-3.6 1-4.9 2.7zM70 83.5h.2a8 8 0 000-.5 116.3 116.3 0 00-1-17.2c-.6-3.1-1.8-5.4-3.4-7a8.3 8.3 0 00-5.7-2.3c-2.1 0-3.9 1.2-5.2 3a21.2 21.2 0 00-3 6.2c-.8 2.3-1.1 6-1.3 9.3a132.4 132.4 0 00-.3 5.8h.2-.2v.3l19.9 2.7v-.3h-.2zM34.8 64.8l.2.1.2.2a29.9 29.9 0 00-1.2 2l-2.9 5a88 88 0 00-6 13l-.3-.2a88.4 88.4 0 018.8-18 159 159 0 011.2-2v-.1zM88 70.3h.3l.2-.2a27.1 27.1 0 01.7 2.4c.5 1.4 1 3.5 1.6 5.8a89.2 89.2 0 012.8 15.5h-.4a88.8 88.8 0 00-4.4-21.2 133.2 133.2 0 00-.7-2.1v-.2zM109 64l.3-.2.1-.2.1.1.3.4a80.6 80.6 0 013.8 5.8c2 3.5 4.4 8 5 11.5h-.4c-.6-3.3-2.9-7.7-5-11.3a95 95 0 00-3.8-5.7l-.2-.4h-.1z" fill="#24286B" />
      <path d="M25 85.3c.3 0 .6-.2.6-.5 0-.2-.3-.5-.7-.5-.4 0-.7.3-.7.5 0 .3.3.5.7.5zM93.2 94c.4 0 .7-.2.7-.4 0-.3-.3-.5-.7-.5-.4 0-.7.2-.7.5 0 .2.3.5.7.5zM118.4 81.8c.3 0 .6-.2.6-.5s-.3-.5-.6-.5c-.4 0-.7.2-.7.5s.3.5.7.5z" fill="#24286B" />
      <path d="M73.3 40c0-.2.2-.2.3-.2.9.5 2.4 1.8 4 4.3a45.7 45.7 0 015 12s-.2 0-.2-.2c-1.6-5.3-3.5-9-5.1-11.5-1.7-2.5-3.1-3.7-4-4.1a.2.2 0 010-.4z" fill="#fff" />
      <path d="M92.7 47c.1-.2.3-.2.3 0l1.3 1c1.8 1.8 3.2 3.1 4.6 4.8a55.2 55.2 0 015.7 9.6h-.3c-2.4-4.7-4-7.2-5.7-9.3-1.4-1.6-2.8-3-4.6-4.7a405.8 405.8 0 01-1.3-1.5z" fill="#24286B" />
      <path d="M106.7 66.6h.3l3 5.5.2.3.2.6.2 1c0 .6-.4 1.2-1.4 1.9-2 1.2-5.1 2-6.5 2.3 0 0-.2 0-.2-.2l.1-.3c1.4-.3 4.5-1.1 6.4-2.3 1-.5 1.2-1 1.2-1.5 0-.2 0-.5-.2-.8l-.2-.5-.1-.3-3-5.4v-.3z" fill="#fff" />
    </svg>
  );
};
